'use client'
import './assets/css/global.css'
import dynamic from 'next/dynamic'

const App = dynamic(() => import('./App'), { ssr: false })

export default function CSR_SRC () {
	return (
		<>
			<div id="root">
				<App/>
			</div>
			<div id="loading-mask"></div>
		</>
	)
}
